import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Row } from 'antd';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import SendView from './SendView';

import { useQuery } from 'react-query';
import Dashboard from '../../../common/components/dashboard';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import '../index.scss';
import { getCurrentYear } from '../../../helpers/dateTimeHelpers';

export const imageUrls = [
    {
        id: 1,
        value: 'https://playmoments.app/email/banner.8ac0bdda.png',
        label: 'Banner background (1366 x 500)',
    },
    {
        id: 2,
        value: 'https://playmoments.app/email/kidslide.jpg',
        label: 'Section kid slide (800 x 533)',
    },
    {
        id: 3,
        value: 'https://playmoments.app/email/holdhands.jpg',
        label: 'Section hold hands (800 x 534)',
    },
    {
        id: 4,
        value: 'https://playmoments.app/email/camping.jpg',
        label: 'Section camping (800 x 534)',
    },
    {
        id: 5,
        value: 'https://playmoments.app/email/autumn 1.jpg',
        label: 'autumn 1 (5472 x 3648)',
    },
    {
        id: 6,
        value: 'https://playmoments.app/email/autumn 2.jpg',
        label: 'autumn 2 (6000 x 4000)',
    },
    {
        id: 7,
        value: 'https://playmoments.app/email/autumn 3.jpg',
        label: 'autumn 3 (4572 x 3648)',
    },
    {
        id: 8,
        value: 'https://playmoments.app/email/autumn 4.jpg',
        label: 'autumn 4 (4921 x 3281)',
    },
    {
        id: 6,
        value: 'https://playmoments.app/email/at home play.jpg',
        label: 'at home play (6682 x 4455)',
    }
]

const copyrightText = `@${getCurrentYear()} Play Moments.`

export const langaugesItems = [
    {
        id: 1,
        value: 'en',
        label: 'en',
        title: 'Sparking Joy, one activity at a time',
        subTitle: `Let's Play`,
        copyright: copyrightText,
        unsubscribe: 'Unsubscribe'
    },
    {
        id: 2,
        value: 'ro',
        label: 'ro',
        title: 'Sparking Joy, o activitate la un moment dat',
        subTitle: `Să ne jucăm`,
        copyright: copyrightText,
        unsubscribe: 'Dezabonați-vă'
    },
]

const SendEmail = () => {

    const navigate = useNavigate();

    const location = useLocation()

    const { httpPostAsync } = useHttpHelper()

    const sentId = location.state?.copyId

    const fetchDetailsAsync = async () => {
        try {

            const response = await httpPostAsync(appLinks.sentEmailsDetails.replace('{id}', sentId))

            return response

        } catch (error) {
            console.log('error fetching sent emails details', error);
        }
    }

    const {
        data,
        isLoading,
    } = useQuery(
        [queryKeys.sentEmailDetails, sentId],
        fetchDetailsAsync,
        {
            enabled: typeof sentId === 'number' && sentId > 0,
            refetchOnWindowFocus: false
        }
    );

    const onBack = () => {
        try {

            navigate(`/${appRoutes.emails}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const navigateToDetails = (id) => {
        try {

            if (typeof id === 'number' && id > 0) {
                navigate(`/${appRoutes.sendEmailDetails}/${id}`)
            } else {
                navigate(`/${appRoutes.emails}`)
            }

        } catch (error) {
            console.log('error navigating to notification details', error);
        }
    }


    const formValues = useMemo(() => {

        let extraInfo = {}

        if (typeof sentId === 'number' && sentId > 0) {
            if (data?.code === '0' && typeof data?.data === 'object' && data?.data != null) {
                extraInfo = {
                    ...data.data
                }
            }
        } else {
            const item = langaugesItems[0]
            extraInfo = {
                footerLangauge: item,
                footerTitle: item?.title,
                footerSubtitle: item.subTitle,
                copyright: item.copyright,
                unsubscribe: item.unsubscribe
            }
        }

        return {
            ...extraInfo,
        }

    }, [data, sentId])

    return (
        <Dashboard subrouting={false} activeItem='emails'>
            <Row align="middle" style={{ paddingBottom: 8 }}>
                <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                    <Breadcrumb.Item><a onClick={onBack}>Emails</a></Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <SendView
                formValues={formValues}
                navigateToDetails={navigateToDetails}
            />
        </Dashboard>
    )

}

export default SendEmail