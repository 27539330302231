import { useMemo, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from "react-query";
import { Layout, Menu, Row, Avatar, Col, Typography } from 'antd';
import Icon, {
    // DesktopOutlined,
    PieChartOutlined,
    UserOutlined,
    LogoutOutlined,
    DashboardOutlined,
    TeamOutlined,
    MessageOutlined,
    NotificationOutlined,
    StopOutlined,
    MailOutlined,
    CalendarOutlined,
} from '@ant-design/icons';
import { ReactComponent as Globe } from '../../../assets/Globe_iconnew.svg';
import { ReactComponent as Group } from '../../../assets/group-1-512-_1_.svg';
import { ReactComponent as Achievment } from '../../../assets/Achievement-512.svg';
import { ReactComponent as OneAppLogo } from '../../../assets/adminlogobeta.svg';
import { useAppContext } from '../appContext';
import { appRoutes } from '../../../config/constants';
import { useMenuPermissionsContext, usePermssionContext } from '../permissionContext';
import { useFilterContext } from '../filterStoreContext';
import { getCurrentYear } from '../../../helpers/dateTimeHelpers';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const logoContainerHeight = '60px';
const styles = {
    logo: {
        minHeight: logoContainerHeight,
        paddingLeft: '24px'
    }
}

const routeKeyMapper = {
    dashboard: `/${appRoutes.dashboard}/${appRoutes.dashHome}`,
    challenges: `/${appRoutes.challenges}`,
    calendar: `/${appRoutes.adminArea}/${appRoutes.calender}`,
    users: `/${appRoutes.dashboard}/${appRoutes.users}`,
    messages: `/${appRoutes.messages}`,
    'onekids-posts': `/${appRoutes.oneKidsPosts}`,
    posts: `/${appRoutes.posts}`,
    org: `/${appRoutes.organisations}`,
    clubs: `/${appRoutes.clubs}`,
    notifications: `/${appRoutes.notifications}`,
    emails: `/${appRoutes.emails}`,
    reportedItems: `/${appRoutes.reported}`,
    'settings-themes': `/${appRoutes.themes}`,
    'settings-skills': `/${appRoutes.skills}`,
    'settings-difficulty': `/${appRoutes.difficulty}`,
    'settings-role-permissions': `/${appRoutes.rolePermissions}`,
    'settings-ages': `/${appRoutes.ages}`,
    'settings-materials': `/${appRoutes.materials}`,
    sessionLogs: `/${appRoutes.dashboard}/${appRoutes.sessionLogs}`,
    npsresponses:  `/${appRoutes.dashboard}/${appRoutes.npsResponses}`,
}

export default function Dashboard(props) {

    const { isAuthenticated, onLogoutAsync, user } = useAppContext();

    let location = useLocation();

    const {
        hasSettingsMenuAccess = false,
        hasUsersMenuAccess = false,
        hasChallengesMenuAccess = false,
        // hasCreatorsMenuAccess = false,
        hasOrganisationsMenuAccess = false,
        hasClubsMenuAccess = false,
        hasThemeSubmenuAccess = false,
        hasSkillsSubmenuAccess = false,
        hasDifficultySubmenuAccess = false,
        // hasDurationSubmenuAccess = false,
        hasRolePermissionsSubmenuAccess = false,
        hasPostsMenuAccess = false,
        hasOneKidsMenuAccess = false,
        hasNotificationsPermission = true,
        hasUserMessagesPermission = true,
        hasReportedItemsPermission = true,
        hasAgesPermission = true,
        hasMaterialsPermission = true,
        hasEmailPermission = true,
        hasCalendarPermission = false,
        hasDashboardAccess = true,
    } = useMenuPermissionsContext();

    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState()
    const [collapsed, setCollapsed] = useState(false);

    const menuItems = useMemo(() => {

        const items = [
            {
                label: 'Dashboard',
                key: 'dashboard',
                icon: <DashboardOutlined />,
                disabled: !hasDashboardAccess
            },
        ]

        if (hasChallengesMenuAccess) {
            items.push(
                {
                    label: 'Challenges',
                    key: 'challenges',
                    icon: <Icon component={Achievment} />,
                }
            )
        }

        if (hasCalendarPermission) {
            items.push(
                {
                    label: 'Calendar',
                    key: 'calendar',
                    icon: <Icon component={CalendarOutlined} />,
                }
            )
        }

        if (hasUsersMenuAccess) {
            items.push(
                {
                    label: 'Users',
                    key: 'users',
                    icon: <UserOutlined />,
                }
            )
        }

        if (hasUserMessagesPermission) {
            items.push(
                {
                    label: 'Messages',
                    key: 'messages',
                    icon: <MessageOutlined />,
                }
            )
        }

        if (hasPostsMenuAccess) {
            items.push(
                {
                    label: 'Posts',
                    key: 'posts',
                    icon: <Icon component={Group} />,
                }
            )
        }

        if (hasOneKidsMenuAccess) {
            items.push(
                {
                    label: 'One Kids Posts',
                    key: 'onekids-posts',
                    icon: <Icon component={Group} />,
                }
            )
        }

        if (hasOrganisationsMenuAccess) {
            items.push(
                {
                    label: 'Organisations',
                    key: 'org',
                    icon: <Icon component={Globe} />,
                }
            )
        }

        if (hasClubsMenuAccess) {
            items.push(
                {
                    label: 'Clubs',
                    key: 'clubs',
                    icon: <TeamOutlined />,
                }
            )
        }

        if (hasNotificationsPermission) {
            items.push(
                {
                    label: 'Notifications',
                    key: 'notifications',
                    icon: <NotificationOutlined />,
                }
            )
        }


        if (hasEmailPermission) {
            items.push(
                {
                    label: 'Emails',
                    key: 'emails',
                    icon: <MailOutlined />,
                }
            )
        }


        if (hasReportedItemsPermission) {
            items.push(
                {
                    label: 'Reported',
                    key: 'reportedItems',
                    icon: <StopOutlined />,
                }
            )
        }

        items.push(
            {
                label: 'Session Logs',
                key: 'sessionLogs',
                icon: <TeamOutlined />,
            }
        )

        items.push(
            {
                label: 'Nps Responses',
                key: 'npsresponses',
                icon: <TeamOutlined />,
            }
        )

        // adding sub menu children
        if (hasSettingsMenuAccess) {

            const settingsMenu = {
                label: 'Configurations',
                key: 'settings',
                icon: <PieChartOutlined />,
                children: []
            }

            if (hasThemeSubmenuAccess) {
                settingsMenu.children.push({
                    label: 'Themes',
                    key: 'settings-themes'
                })

            }

            if (hasSkillsSubmenuAccess) {
                settingsMenu.children.push({
                    label: 'Skills',
                    key: 'settings-skills',
                })
            }

            if (hasDifficultySubmenuAccess) {
                settingsMenu.children.push({
                    label: 'Difficulty Levels',
                    key: 'settings-difficulty',
                })
            }

            if (hasRolePermissionsSubmenuAccess) {
                settingsMenu.children.push({
                    label: 'Role Permissions',
                    key: 'settings-role-permissions',
                })
            }

            if (hasAgesPermission) {
                settingsMenu.children.push({
                    label: 'Ages',
                    key: 'settings-ages',
                })
            }


            if (hasMaterialsPermission) {
                settingsMenu.children.push({
                    label: 'Materials',
                    key: 'settings-materials',
                })
            }

            if (!!settingsMenu.children?.length) {
                items.push(settingsMenu)
            }
        }

        items.push({
            label: 'Logout',
            key: 'logout',
            icon: <LogoutOutlined />
        })

        return items

    }, [
        hasDashboardAccess,
        hasCalendarPermission,
        hasUsersMenuAccess,
        hasUserMessagesPermission,
        hasPostsMenuAccess,
        hasOneKidsMenuAccess,
        hasOrganisationsMenuAccess,
        hasClubsMenuAccess,
        hasNotificationsPermission,
        hasEmailPermission,
        hasReportedItemsPermission,
        hasSettingsMenuAccess,
        hasThemeSubmenuAccess,
        hasSkillsSubmenuAccess,
        hasDifficultySubmenuAccess,
        hasRolePermissionsSubmenuAccess,
        hasAgesPermission,
        hasMaterialsPermission,
    ])

    const onSelectMenu = ({ key }) => {
        try {
            const route = routeKeyMapper[key]

            console.log('key, route', key, route)

            if (key === 'logout') {
                onLogoutClick()
            } else if (typeof route === 'string') {
                navigate(route)

                if (key === 'user') {
                    setSelectedItem("users")
                }
            }

        } catch (error) {
            console.log('error on select menu')
        }
    }

    // using hooks before the return;
    const { resetPermissions } = usePermssionContext();
    const { resetFilterState } = useFilterContext();
    const queryClient = useQueryClient();

    if (!isAuthenticated) {
        console.log('unauthenticated redirect back to login')
        return (<Navigate to="/login" state={{ from: location }} replace />);
    }

    const { 
        openKeys = [],
        subrouting = true
     } = props;

    let locationItem = undefined
    if (['users', 'editUser', 'user-details', 'new-user'].find(e => location.pathname?.includes(e))) {
        locationItem = 'users'
    }

    const activeItem = props.activeItem ?? selectedItem ?? locationItem

    //#region  actions
    const onLogoutClick = () => {
        try {
            onLogoutAsync();
            resetPermissions && resetPermissions();
            resetFilterState && resetFilterState();
            queryClient.clear();
            navigate('/login', { state: {}, replace: true })
        } catch (error) {
            console.log('error logging out', error);
        }
    }

    const onCollapse = collapsed => {
        console.log(collapsed);
        setCollapsed(collapsed);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
                breakpoint="lg"
                collapsedWidth="0"
                zeroWidthTriggerStyle={{
                    borderBottomRightRadius: 22,
                    backgroundColor: '#002140'
                }}
            >
                <div style={styles.logo} >
                    <Row align="middle" style={{ minHeight: logoContainerHeight }} >
                        <OneAppLogo fill="#fff" height='38px' width="78px" />
                    </Row>
                </div>
                <Menu
                    items={menuItems}
                    theme="dark"
                    defaultSelectedKeys={[activeItem]}
                    defaultOpenKeys={openKeys}
                    mode="inline"
                    onSelect={onSelectMenu}
                />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} >
                    <Row justify="end">
                        <Col>
                            <Row align="middle" style={{ marginRight: 28 }}>
                                <Avatar size={32} style={{ marginRight: 8 }} icon={<UserOutlined />} />
                                <Text style={{
                                    color: '#fff'
                                }}>{user.username}</Text>
                            </Row>
                        </Col>
                    </Row>
                </Header>
                <Content style={{ margin: '0 16px', paddingTop: 20 }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        {subrouting ? <Outlet /> : props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    &copy; {getCurrentYear()} Play Moments
                </Footer>
            </Layout>
        </Layout>
    );
}